import React from "react";
import StandardHeader from "../assets/elements/StandardHeader";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import Cta from "../partials/Cta";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import {
  CogIcon,
  CubeIcon,
  CodeIcon,
  SparklesIcon,
  TerminalIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";

import imgSarInstanceSegmentationWebm from "../assets/images/sar-auto-segmentation.webm";
import Layout from "../components/layout";
import SEO from "../components/seo";
import VideoComponent from "../components/VideoComponent";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Automate",
    description:
      "The most sophisticated labeling automation features in the market.",
    bullets: [
      "Build ensembles of micro-models",
      "Utilise automation feature suite",
      "Incorporate problem-specific heuristics",
    ],
    image: (
      <VideoComponent
        loop
        autoPlay
        muted
        playsinline
        width={useCasesImageWidth}
        height={useCasesImageHeight}
      >
        <source src={imgSarInstanceSegmentationWebm} type="video/webm" />
      </VideoComponent>
    ),
  },
  {
    name: "Evaluate",
    description:
      "Automated data quality assessments discover errors in your ground truth.",
    bullets: [
      "Version your label sets",
      "Validate model performance",
      "Discover classification & geometric errors",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/quality-pred-iou-grid-in-program-cropped.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Evaluate"}
      />
    ),
  },
  {
    name: "Iterate",
    description:
      "Seamlessly integrate our APIs & SDK with your model data loaders.",
    bullets: [
      "Import model predictions",
      "Facilitate active learning pipelines",
      "Prototype new models and ontologies",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/feature-encord-model-import.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Iterate"}
      />
    ),
  },
];

const featuresGrid = [
  {
    name: "Flexible tools",
    description:
      "Our software support a wide variety of computer vision modalities. Multi-faceted data loaders and parsers fits your use case.",
    icon: CubeIcon,
  },
  {
    name: "Automated labeling",
    description:
      "Use our object  tracking & interpolation features to reduce costs. Use micro-models to accelerate your active learning workflows and get to production faster.",
    icon: LightningBoltIcon,
  },
  {
    name: "Configurable taxonomy",
    description:
      "Create arbitrarily rich nested labeling structures accommodating all label modalities in one place.",
    icon: CogIcon,
  },
  {
    name: "Version",
    description:
      "Iterate and experiment with label structures by branching out projects and adding custom filters.",
    icon: CodeIcon,
  },
  {
    name: "API & SDK",
    description:
      "Programmatically access, monitoring, and deployment of labels and data assets into your machine learning infrastructure.",
    icon: TerminalIcon,
  },
  {
    name: "Quality",
    description:
      "Access automated assessment and visualisation tools to get precise estimations of your label quality and model performance.",
    icon: SparklesIcon,
  },
];

const MachineLearning = ({ location }) => {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        title={
          "The fastest way to get high quality annotation data for machine learning"
        }
        description={
          "Encord's flexible APIs & SDK will allow you to stream annotations directly to your data loaders with a few lines of code."
        }
      />

      {/* Use cases */}
      <ThreeColumnUseCaseSection
        caseStudy={null}
        title={"How it works"}
        header={"Unlock data-centric AI for computer vision"}
        description={
          <>
            Encord offers the most sophisticated automated data annotation &
            evaluation solution in the market.
          </>
        }
        useCases={useCases}
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"Features"}
        header={"Manage, version, experiment"}
        description={
          "We built Encord to support you as your company adopt and scales computer vision AI applications."
        }
        features={featuresGrid}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={"Invest"}
        header={"Our investors 2"}
        description={"Our investors"}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/quality-pred-iou-grid-in-program.png"}
            width={550}
            alt="IOU Grid"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../assets/images/branch-project.png"}
            width={500}
            alt="Branch project"
          />
        }
        headerOne={"Debug label quality"}
        headerTwo={"Branch projects"}
        descriptionOne={
          "Use our automated quality control features to ensure only the best ground truth is delivered to your models."
        }
        descriptionTwo={
          "Our labelset management capabilities allow you to experiment fluidly with versioned data and labels, and set up custom pipelines and filters."
        }
      />

      <Cta
        location={location}
        pageName={"Machine Learning Page"}
        ctaText={"An Active Learning Pipeline for MLOps"}
      />
    </Layout>
  );
};

export default MachineLearning;

export const Head = () => (
  <SEO
    title="A Platform for ML Teams - Stream Annotated Training Data | Encord"
    description="Encord offers flexible APIs and SDKs to allow you to stream labels directly to your data loaders."
  >
    <meta name="robots" content="noindex, nofollow" />
  </SEO>
);
